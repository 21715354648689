export default {
  newKeyword: {
    id: 'card_new_keyword',
    defaultMessage: 'new',
  },
  afterBuyback: {
    id: 'card_after_buyback',
    defaultMessage: 'after tradein',
  },
} as const
