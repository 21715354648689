export default {
  compare: {
    id: 'search_comparison_tool_hover_compare',
    defaultMessage: 'Compare',
  },
  clear: {
    id: 'search_comparison_tool_hover_clear',
    defaultMessage: 'Clear',
  },
  compareDevices: {
    id: 'search_comparison_tool_selection_title',
    defaultMessage: 'Compare devices',
  },
  selectProducts: {
    id: 'search_comparison_tool_selection_subtitle',
    defaultMessage: 'Select 2 or more products',
  },
  clearAll: {
    id: 'search_comparison_tool_selection_clear',
    defaultMessage: 'Clear all',
  },
  modalTitle: {
    id: 'comparison_tool_modal_title',
    defaultMessage: 'Model comparison',
  },
  seeOptions: {
    id: 'comparison_tool_modal_cta',
    defaultMessage: 'See options',
  },
  startingFrom: {
    id: 'card_starting_from',
    defaultMessage: 'Starting from',
  },
  scores: {
    id: 'comparison_tool_scores',
    defaultMessage: 'Scores',
  },
  miscelaneous: {
    id: 'comparison_tool_modal_item_miscellaneous',
    defaultMessage: 'Miscelaneous',
  },
  durability: {
    id: 'comparison_tool_life_expectancy_score',
    defaultMessage: 'Durability',
  },
  performance: {
    id: 'comparison_tool_performance_score',
    defaultMessage: 'Performance',
  },
  screenQuality: {
    id: 'comparison_tool_screen_quality_score',
    defaultMessage: 'Screen quality',
  },
  soundQuality: {
    id: 'comparison_tool_sound_quality_score',
    defaultMessage: 'Sound quality',
  },
  multimedia: {
    id: 'comparison_tool_camera_score',
    defaultMessage: 'Audiovisual',
  },
  camera: {
    id: 'comparison_tool_multimedia_score',
    defaultMessage: 'Camera',
  },
  loading: {
    id: 'spinner',
    defaultMessage: 'Loading',
  },
  cardPlaceholder: {
    id: 'search_comparison_tool_selection_skeleton',
    defaultMessage: 'Add another device to compare',
  },
  importanceTitle: {
    id: 'comparison_tool_modal_details_title_importance',
    defaultMessage: 'Why it’s important',
  },

  durabilityMacbookImportance: {
    id: 'comparison_tool_modal_life_expectancy_macbook_importance',
    defaultMessage:
      'Since MacBooks are known for their long lifespan, often lasting well beyond 5 to 7 years with proper care, a great score means you’re investing in a device that will stay reliable and up-to-date for as long as possible.',
  },
  durabilityIpadImportance: {
    id: 'comparison_tool_modal_life_expectancy_ipad_importance',
    defaultMessage:
      'Since iPads are designed to provide years of seamless performance, often from five or more years, a high score means you’re choosing a device that will deliver long-term value and keep up with evolving software demands.',
  },
  durabilitySmartphoneImportance: {
    id: 'comparison_tool_modal_life_expectancy_smartphone_importance',
    defaultMessage:
      'Since smartphones are essential daily tools, often expected to last from 3 to 5 years or more, a great score ensures you’re selecting a device that will stay reliable, perform well, and receive critical software updates throughout its lifespan.',
  },
  performancesMacbookImportance: {
    id: 'comparison_tool_modal_performances_macbook_importance',
    defaultMessage:
      'A high-performance MacBook ensures smooth multitasking, faster processing speeds, and seamless handling of demanding tasks like video editing, software development, and gaming, providing a more efficient and responsive user experience.',
  },
  performancesIpadImportance: {
    id: 'comparison_tool_modal_performances_ipad_importance',
    defaultMessage:
      'A high-performance iPad means it’ll have smooth multitasking, quick app launches, and lag-free performance for tasks like gaming, video editing, and graphic design.',
  },
  performancesSmartphoneImportance: {
    id: 'comparison_tool_modal_performances_smartphone_importance',
    defaultMessage:
      'A high-performing smartphone offers faster app launches, smoother multitasking, and better responsiveness for tasks like gaming, video streaming, and running demanding apps for a seamless user experience.',
  },
  screenPerformancesMacbookImportance: {
    id: 'comparison_tool_modal_screen_performances_macbook_importance',
    defaultMessage:
      'A high-quality screen means it’ll have a clearer, more vibrant display, making tasks like viewing photos, watching videos, and reading text more enjoyable and less straining on the eyes.',
  },
  screenPerformancesIpadImportance: {
    id: 'comparison_tool_modal_screen_performances_ipad_importance',
    defaultMessage:
      'A high-quality screen on an iPad provides sharper images, smoother scrolling, and more accurate colors, making it ideal for tasks like drawing, watching videos, and reading eBooks with greater clarity and comfort.',
  },
  screenPerformancesSmartphoneImportance: {
    id: 'comparison_tool_modal_screen_performances_smartphone_importance',
    defaultMessage:
      'A high-quality screen on a smartphone means it’ll have vibrant colors, sharp images, and smooth touch responsiveness, providing a better experience for everything from browsing and gaming to watching videos and viewing photos.',
  },
  multimediaMacbookImportance: {
    id: 'comparison_tool_modal_multimedia_macbook_importance',
    defaultMessage:
      'A higher audiovisual score means clearer video calls with sharper image quality and richer, more immersive audio for media consumption, making the MacBook ideal for virtual meetings, video streaming, and entertainment.',
  },
  cameraSmartphoneImportance: {
    id: 'comparison_tool_modal_camera_smartphone_importance',
    defaultMessage:
      'A higher camera quality score means sharper, more detailed photos and videos, better low-light performance, and enhanced features like portrait mode and facial recognition, making it ideal for capturing professional-quality images and selfies.',
  },
  soundQualityIpadImportance: {
    id: 'comparison_tool_modal_sound_quality_ipad_importance',
    defaultMessage:
      'A higher sound quality score means clearer, richer audio for media playback, gaming, and video calls, offering an immersive listening experience without distortion.',
  },
} as const
